module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="wizardWarningDialog" class="modal fade" tabindex="-1">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title">' +
__e( wizardWarningHeader ) +
'</h4>\n                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body delete-item-body-prompt">\n              <span>\n                ';
 if (!hideIcon) { ;
__p += '\n                  <span class="alert_icon icon-alert-warning"></span>\n                ';
 } ;
__p += '\n                <span>\n                  <p>' +
((__t = ( wizardWarningBodyPrompt )) == null ? '' : __t) +
'</p>\n                  <ul class="terminateOnlyBulletPoints" >' +
((__t = ( firstBulletPoint )) == null ? '' : __t) +
' </ul>\n                  <ul class="terminateOnlyBulletPoints">' +
((__t = ( secondBulletPoint )) == null ? '' : __t) +
' </ul>\n                  <p>' +
((__t = ( proceedPrompt )) == null ? '' : __t) +
'</p>\n                </span>\n              </span>\n            </div>\n            <div class="modal-footer">\n                <button type="button" id="warningCancelWizardButton" class="btn btn_color_blue add_indent_10 cancelWizardBtn" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( cancelWizardButtonLabel )) == null ? '' : __t) +
'</button>\n                <button type="button" id="warningExitWizardButton" class="btn btn_color_blue companion_btn exitWizardBtn" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( exitWizardButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};
