module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="wizardWarningDialog" class="modal fade" tabindex="-1">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title">' +
((__t = ( title )) == null ? '' : __t) +
'</h4>\n        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>\n      </div>\n      <div class="modal-body delete-item-body-prompt">\n        <span>\n          ';
 if (!hideIcon) { ;
__p += '\n            <span class="alert_icon icon-alert-warning"></span>\n            ';
 } ;
__p += '\n              <span>\n                <p>\n                  ' +
((__t = ( wizardWarningBodyPrompt )) == null ? '' : __t) +
'\n                </p>\n                <div class="wizardWarningRadioArea role="alert">\n                  <div>\n                    <label for="stopRadioButton" class="wizardWarningRadioLabel">\n                      <input class="wizardWarningRadioButton" type="radio" id="stopRadioButton" name="AWSParallelServerRadioButtons" value="stop" checked>\n                      <p class="wizardWarningRadioText">' +
((__t = ( stopText )) == null ? '' : __t) +
'</p>\n                    </label>\n                    <label for="terminateRadioButton" class="wizardWarningRadioLabel">\n                      <input class="wizardWarningRadioButton" type="radio" id="terminateRadioButton" name="AWSParallelServerRadioButtons" value="terminate">\n                      <p class="wizardWarningRadioText">' +
((__t = ( terminateText )) == null ? '' : __t) +
'</p>\n                    </label>\n                    <div class="additionalDetailHeader">\n                      <span class="horizontalIconContainer">\n                        <a id="wizardWarningExpandoButton" onclick="return false" class="sectionExpander">\n                          <h2 class="wizardWarningExpandoTitle wizardWarningDialogAWSParallelServer subtitle">More details</h2>\n                          <span id="wizardWarningExpandoIcon" class="wizardWarningExpandoTitle wizardWarningDialogAWSParallelServer icon-arrow-open-right"></span>\n                        </a>\n                      </span>\n                    </div>\n                    <p hidden=true id="wizardWarningExpandoText">' +
((__t = ( moreDetails )) == null ? '' : __t) +
'</p>\n                  </div>\n                </div>\n                <br>\n                <p>\n                  ' +
((__t = ( proceedPrompt )) == null ? '' : __t) +
'\n                </p>\n              </span>\n        </span>\n      </div>\n      <div class="modal-footer">\n        <button type="button" id="warningCancelWizardButton" class="btn btn_color_blue add_indent_10 cancelWizardBtn"\n          data-bs-dismiss="modal" tabindex="0">\n          ' +
((__t = ( cancelWizardButtonLabel )) == null ? '' : __t) +
'\n        </button>\n        <button type="button" id="warningExitWizardButton" class="btn btn_color_blue companion_btn exitWizardBtn"\n          data-bs-dismiss="modal" tabindex="0">\n          ' +
((__t = ( exitWizardButtonLabel )) == null ? '' : __t) +
'\n        </button>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};
